import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/layout';
import SEO from '../components/seo';
import AppOverview from '../components/image-wrappers/store/overview';
import AppTrainingDiary from '../components/image-wrappers/store/training-diary';
import AppGymWorkoutPlan from '../components/image-wrappers/store/gym-workout-plan';
import GymTestimonials from '../components/Store/gym-testimonials';
import HeroSection from '../components/Homepage/hero-section';
import TestimonialSlideshow from '../components/Store/testimonial-slideshow';
import Accordion from '../components/accordion';
import { transformationPlanStyle } from '../styles/transformation-plan-style';

const HomePage = ({ hero, features, gym_included, testimonials, footer }) => {
    const { heading, description, feature_items, slider_images } = features;
    return (
        <Layout>
            <SEO title="J Clarke Fitness | Online Personal Training" />
            <HeroSection data={hero}>
                <h1 style={{ color: '#f44336' }}>{hero.title}</h1>
                <hr className="black" />
                <p style={{ color: 'black' }}>
                    <strong>{hero.subtitle}</strong>
                </p>
                <a target="_blank" href="https://ihjio7md9ek.typeform.com/to/GoXEwTa0" className="button">
                    Apply Now
                </a>
            </HeroSection>
            <div className="hero">
                <div>
                    <div className="text">
                        <h1>{heading}</h1>
                        <p>{description}</p>
                        <ul>
                            {feature_items.map((i, k) => (
                                <li key={k}>{i}</li>
                            ))}
                        </ul>
                        <a
                            className="button no-shadow"
                            target="_blank"
                            href="https://ihjio7md9ek.typeform.com/to/GoXEwTa0"
                            style={{ fontSize: '110%', marginTop: 20, padding: '8px 20px' }}
                        >
                            Apply Now
                        </a>
                        {/* <NewsletterForm label="Leave your email to get notified as soon as this program is released in September!" /> */}
                    </div>
                    <TestimonialSlideshow slides={slider_images} />
                </div>
            </div>
            <h2 style={{ textAlign: 'center' }}>WHAT’S INCLUDED</h2>
            <div className="features">
                {gym_included.map((i, x) => (
                    <div key={x}>
                        <div className="image shadow-md">
                            <Img fluid={i.image.childImageSharp.fluid} alt={i.name} />
                        </div>
                        <div className="text">
                            <h2>{i.heading}</h2>
                            <ul>
                                {i.items.map((e, el) => (
                                    <li
                                        key={el}
                                        dangerouslySetInnerHTML={{ __html: e.text_html }}
                                    />
                                ))}
                            </ul>
                        </div>
                    </div>
                ))}
            </div>

            <hr className="narrow" />

            <div className="gutter-container">
                <div>
                    <h2 style={{ margin: '0', textAlign: 'center' }}>Your account</h2>
                    <p>
                        You will get access to all of the above and more in your very own account on
                        jclarkefitness.com!
                    </p>

                    <div className="portal-images">
                        <AppOverview />
                        <AppTrainingDiary />
                        <AppGymWorkoutPlan />
                    </div>

                    <h2 style={{ textAlign: 'center' }}>Testimonials</h2>
                    <p>These clients have transformed their bodies on my programs - you can too!</p>

                    <GymTestimonials data={testimonials} />

                    <div style={{ textAlign: 'center' }}>
                        <a
                            className="button no-shadow"
                            target="_blank"
                            href="https://ihjio7md9ek.typeform.com/to/GoXEwTa0"
                            style={{ fontSize: '110%', marginTop: 20, padding: '8px 20px' }}
                        >
                            Apply Now
                        </a>
                    </div>
                </div>
            </div>
            <div className="wrapper-dark">
                <div className="final-section features">
                    <div className="img-container">
                        <img
                            src={footer.image.childImageSharp.fixed.src}
                            alt="JC Fitness Gym Program"
                        />
                    </div>
                    <div className="content">
                        <div dangerouslySetInnerHTML={{ __html: footer.content_html }} />
                        <a
                            className="button white no-shadow"
                            target="_blank"
                            href="https://ihjio7md9ek.typeform.com/to/GoXEwTa0"
                            style={{
                                fontSize: '110%',
                                marginTop: 20,
                                padding: '8px 20px',
                                backgroundColor: 'white',
                                color: 'red',
                            }}
                        >
                            Apply Now
                        </a>
                    </div>
                </div>
            </div>
            <style jsx>{transformationPlanStyle}</style>
            <style jsx>{`
                :global(div > h2) {
                    font-size: 32px;
                    line-height: 48px;
                    margin: 32px 0;
                }

                :global(a.button) {
                    margin-top: 0;
                }

                :global(a.button.white) {
                    margin-left: 8px;
                    background: #fff;
                    color: #263238;

                    &:active {
                        background-color: hsl(200, 0%, 82%);
                        color: hsl(200, 19%, 0%);
                    }
                }
                .wrapper-dark {
                    background-color: #eb6059;
                    color: white;

                    & .hero-cta {
                        color: rgba(38, 50, 56, 0.8);
                    }
                }
                .final-section {
                    margin: 0 auto;
                    display: flex;
                    align-items: flex-start;
                    & .img-container {
                        flex: 1;
                        margin: 0;
                        margin-right: 30px;
                        display: none;
                        & img {
                            object-fit: cover;
                            width: 100%;
                        }
                    }
                    & .content {
                        flex: 2;
                        display: block;
                        margin-top: 0;
                        & :global(p) {
                            margin-top: 0;
                            margin-bottom: 20px;
                            color: inherit;
                            font-size: 22px;
                        }
                    }
                }
                @media (min-width: 650px) {
                    h1 {
                        font-size: 42px;
                    }
                    .final-section {
                        & .img-container {
                            display: block;
                        }
                    }
                }
            `}</style>
        </Layout>
    );
};

const homePageQuery = graphql`
    query HomePageQuery {
        allMarkdownRemark(filter: { frontmatter: { pageName: { eq: "home" } } }) {
            edges {
                node {
                    frontmatter {
                        hero {
                            image {
                                small: childImageSharp {
                                    fluid(maxWidth: 1200) {
                                        src
                                    }
                                }
                                md: childImageSharp {
                                    fluid(maxWidth: 1200) {
                                        src
                                    }
                                }
                                xl: childImageSharp {
                                    fluid(maxWidth: 2800) {
                                        src
                                    }
                                }
                            }
                            subtitle
                            title
                        }
                        features {
                            heading
                            description
                            feature_items
                            slider_images {
                                image {
                                    childImageSharp {
                                        fluid(maxWidth: 500) {
                                            ...GatsbyImageSharpFluid_withWebp
                                        }
                                    }
                                }
                            }
                        }
                        gym_included {
                            heading
                            items {
                                text_html
                            }
                            image {
                                childImageSharp {
                                    fluid(maxWidth: 500) {
                                        ...GatsbyImageSharpFluid_withWebp
                                    }
                                }
                            }
                        }
                        footer {
                            content_html
                            image {
                                childImageSharp {
                                    fixed(width: 500) {
                                        src
                                    }
                                }
                            }
                        }
                        home_testimonials
                    }
                }
            }
        }
        testimonials: allMarkdownRemark(
            filter: { frontmatter: { collection: { eq: "testimonials" } } }
        ) {
            edges {
                node {
                    frontmatter {
                        name
                        duration
                        text: testimonial
                        image {
                            childImageSharp {
                                fluid(maxWidth: 500) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export default () => (
    <StaticQuery
        query={homePageQuery}
        render={data => {
            const {
                edges: [edge],
            } = data.allMarkdownRemark;
            const { frontmatter } = edge.node;
            const testimonials = data.testimonials.edges
                .filter(t => frontmatter.home_testimonials.includes(t.node.frontmatter.name))
                .map(i => i.node.frontmatter);

            return <HomePage {...frontmatter} testimonials={testimonials} />;
        }}
    />
);